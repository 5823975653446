import { ampli } from "~/ampli/index";
var loadPromise = ampli.load({
  // Note Sven: commented out, since our settings only seem to have one environment anyway
  // environment: process.env.buildVariant === "prod" ? "production" : "development",
  environment: "production",
  client: {
    apiKey: "bc4c2153f9ae427650297510cd54c0e7",
    configuration: {
      defaultTracking: false,
      serverUrl: "https://collect.estaid.dk"
    }
  }
}).promise;
export default (function (context, inject) {
  inject("amplitude", ampli);
});
export { ampli, loadPromise };